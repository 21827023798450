@import "~antd/dist/antd.less";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Oswald:wght@700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: "Montserrat", sans-serif;
  }
}
.bg-banner {
  height: 820px;
}
@media (max-width: 992px) {
  .bg-banner {
    height: 420px;
  }
}
.arrowDown {
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }

  50% {
    -webkit-transform: translate(0, 10px);
    transform: translate(0, 10px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@primary-color: #008900;